import axios from 'axios';
import ToastService from '../../service/toast/ToastService';
import ConstantMessages from '../../ContantMessages';

const { REACT_APP_AUTH_TOKEN, REACT_APP_API } = process.env;

const mergeCommonHeaders = headers => {
  const authToken = localStorage.getItem(REACT_APP_AUTH_TOKEN);

  return authToken
    ? { ...headers, Authorization: `Bearer ${authToken}` }
    : headers;
};

const client = axios.create({
  baseURL: REACT_APP_API,
  responseType: 'json'
});

client.interceptors.request.use(request => {
  const headers = mergeCommonHeaders(request.headers.common);
  request.headers.common = headers;
  return request;
});

client.interceptors.response.use(
  success => {
    return success.data;
  },
  error => {
    const response = error.response.data;
    if (error.response.status === 500) {
      if (response.message === 'jwt expired') {
        localStorage.clear();
        ToastService.warn('Session expired');
        setTimeout(() => (window.location = '/login'), 1000);
      } else {
        ToastService.error('An unexpected error has ocurred');
      }
    }
    if (error.response.status === 401) {
      if (response.message === 'jwt expired') {
        localStorage.clear();
        ToastService.warn('Session expired');
        setTimeout(() => (window.location = '/login'), 1000);
      } else {
        ToastService.error('An unexpected error has ocurred');
      }
    } else {
      const message =
        response.message && typeof response.message !== 'object'
          ? ConstantMessages[response.message]
          : 'An unexpected error has ocurred';
      ToastService.error(message);
    }

    return Promise.reject(response);
  }
);

export default client;
