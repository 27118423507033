import {
  LINK_CATEGORY_SUCCESS,
  LINK_CATEGORY_FAIL,
  LOAD_LINK_CATEGORY
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.linkCategory, action) {
  switch (action.type) {
    case LINK_CATEGORY_SUCCESS:
      return { ...action.payload.data };
    case LINK_CATEGORY_FAIL:
      return state;
    case LOAD_LINK_CATEGORY:
      return { ...action.linkCategory };
    default:
      return state;
  }
}
