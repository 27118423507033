import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncPageMembers = AsyncComponent(() =>
  import('../../../pages/pageMembers/index')
);

export default [
  {
    path: '/page-members',
    component: AsyncPageMembers,
    isAuthenticated: true
  }
];
