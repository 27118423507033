import { LINK_CATEGORIES_SUCCESS, LINK_CATEGORIES_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.linkCategories, action) {
  switch (action.type) {
    case LINK_CATEGORIES_SUCCESS:
      return { ...action.payload.data };
    case LINK_CATEGORIES_FAIL:
      return state;
    default:
      return state;
  }
}
