import {
  INSTITUTION_USERS_SUCCESS,
  INSTITUTION_USERS_FAIL
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.institutions, action) {
  switch (action.type) {
    case INSTITUTION_USERS_SUCCESS:
      return { ...action.payload.data };
    case INSTITUTION_USERS_FAIL:
      return state;
    default:
      return state;
  }
}
