import { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.loading, action) {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return { ...action.payload.data };
    case RESET_PASSWORD_FAIL:
      return state;
    default:
      return state;
  }
}
