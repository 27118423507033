import { BANNER_SUCCESS, BANNER_FAIL, LOAD_BANNER } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.banner, action) {
  switch (action.type) {
    case BANNER_SUCCESS:
      return { ...action.payload.data };
    case BANNER_FAIL:
      return state;
    case LOAD_BANNER:
      return { ...action.banner };
    default:
      return state;
  }
}
