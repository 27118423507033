import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const asyncLogin = AsyncComponent(() => import('../../../pages/login/Login'));
const asyncDashBoard = AsyncComponent(() =>
  import('../../../pages/dashboard/Dashboard')
);

const asyncSettings = AsyncComponent(() =>
  import('../../../pages/settings/SettingsGrid')
);

export default [
  {
    path: '/login',
    component: asyncLogin,
    isAuthenticated: false
  },
  {
    path: '/',
    component: asyncDashBoard,
    isAuthenticated: true
  },
  {
    path: '/settings',
    component: asyncSettings,
    isAuthenticated: true
  }
];
