export const START_LOADER = 'START_LOADER'
export const STOP_LOADER = 'STOP_LOADER'

export const LOGIN = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_REQUEST_FAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const LOAD_PUBLICATION_CATEGORY = 'LOAD_PUBLICATION_CATEGORY'
export const INACTIVE_PUBLICATION_CATEGORY =
  'INACTIVE_PUBLICATION_CATEGORY_REQUEST'
export const PUBLICATION_CATEGORY = 'PUBLICATION_CATEGORY_REQUEST'
export const PUBLICATION_CATEGORY_SUCCESS =
  'PUBLICATION_CATEGORY_REQUEST_SUCCESS'
export const PUBLICATION_CATEGORY_FAIL =
  'PUBLICATION_CATEGORY_REQUEST_FAIL'
export const PUBLICATION_CATEGORIES = 'PUBLICATION_CATEGORIES_REQUEST'
export const PUBLICATION_CATEGORIES_SUCCESS =
  'PUBLICATION_CATEGORIES_REQUEST_SUCCESS'
export const PUBLICATION_CATEGORIES_FAIL =
  'PUBLICATION_CATEGORIES_REQUEST_FAIL'

export const LOAD_PUBLICATION_DOCUMENT = 'LOAD_PUBLICATION_DOCUMENT'
export const CREATE_PUBLICATION_DOCUMENT_REQUEST =
  'CREATE_PUBLICATION_DOCUMENT_REQUEST'
export const UPDATE_PUBLICATION_DOCUMENT_REQUEST =
  'UPDATE_PUBLICATION_DOCUMENT_REQUEST'
export const CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL =
  'CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL'
export const REMOVE_PUBLICATION_DOCUMENT_REQUEST =
  'REMOVE_PUBLICATION_DOCUMENT_REQUEST'
export const REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL =
  'REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL'

export const LOAD_BANNER = 'LOAD_BANNER'
export const INACTIVE_BANNER = 'INACTIVE_BANNER_REQUEST'
export const BANNER = 'BANNER_REQUEST'
export const BANNER_SUCCESS = 'BANNER_REQUEST_SUCCESS'
export const BANNER_FAIL = 'BANNER_REQUEST_FAIL'
export const BANNERS = 'BANNERS_REQUEST'
export const BANNERS_SUCCESS = 'BANNERS_REQUEST_SUCCESS'
export const BANNERS_FAIL = 'BANNERS_REQUEST_FAIL'

export const LOAD_CERTIFICATE = 'LOAD_CERTIFICATE'
export const INACTIVE_CERTIFICATE = 'INACTIVE_CERTIFICATE_REQUEST'
export const CERTIFICATE = 'CERTIFICATE_REQUEST'
export const CERTIFICATE_SUCCESS = 'CERTIFICATE_REQUEST_SUCCESS'
export const CERTIFICATE_FAIL = 'CERTIFICATE_REQUEST_FAIL'
export const CERTIFICATES = 'CERTIFICATES_REQUEST'
export const CERTIFICATES_SUCCESS = 'CERTIFICATES_REQUEST_SUCCESS'
export const CERTIFICATES_FAIL = 'CERTIFICATES_REQUEST_FAIL'

export const LOAD_NEWS = 'LOAD_NEWS'
export const INACTIVE_NEWS = 'INACTIVE_NEWS_REQUEST'
export const NEWS = 'NEWS_REQUEST'
export const NEWS_SUCCESS = 'NEWS_REQUEST_SUCCESS'
export const NEWS_FAIL = 'NEWS_REQUEST_FAIL'
export const NEWS_LIST = 'NEWS_LIST_REQUEST'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_REQUEST_SUCCESS'
export const NEWS_LIST_FAIL = 'NEWS_LIST_REQUEST_FAIL'

export const LOAD_LINK_CATEGORY = 'LOAD_LINK_CATEGORY'
export const INACTIVE_LINK_CATEGORY = 'INACTIVE_LINK_CATEGORY_REQUEST'
export const LINK_CATEGORY = 'LINK_CATEGORY_REQUEST'
export const LINK_CATEGORY_SUCCESS = 'LINK_CATEGORY_REQUEST_SUCCESS'
export const LINK_CATEGORY_FAIL = 'LINK_CATEGORY_REQUEST_FAIL'
export const LINK_CATEGORIES = 'LINK_CATEGORIES_REQUEST'
export const LINK_CATEGORIES_SUCCESS = 'LINK_CATEGORIES_REQUEST_SUCCESS'
export const LINK_CATEGORIES_FAIL = 'LINK_CATEGORIES_REQUEST_FAIL'

export const LOAD_LINK = 'LOAD_LINK'
export const INACTIVE_LINK = 'INACTIVE_LINK_REQUEST'
export const LINK = 'LINK_REQUEST'
export const LINK_SUCCESS = 'LINK_REQUEST_SUCCESS'
export const LINK_FAIL = 'LINK_REQUEST_FAIL'
export const LINKS = 'LINKS_REQUEST'
export const LINKS_SUCCESS = 'LINKS_REQUEST_SUCCESS'
export const LINKS_FAIL = 'LINKS_REQUEST_FAIL'

export const LOAD_ADVERTISEMENT_CATEGORY = 'LOAD_ADVERTISEMENT_CATEGORY'
export const INACTIVE_ADVERTISEMENT_CATEGORY =
  'INACTIVE_ADVERTISEMENT_CATEGORY_REQUEST'
export const ADVERTISEMENT_CATEGORY = 'ADVERTISEMENT_CATEGORY_REQUEST'
export const ADVERTISEMENT_CATEGORY_SUCCESS =
  'ADVERTISEMENT_CATEGORY_REQUEST_SUCCESS'
export const ADVERTISEMENT_CATEGORY_FAIL =
  'ADVERTISEMENT_CATEGORY_REQUEST_FAIL'
export const ADVERTISEMENT_CATEGORIES = 'ADVERTISEMENT_CATEGORIES_REQUEST'
export const ADVERTISEMENT_CATEGORIES_SUCCESS =
  'ADVERTISEMENT_CATEGORIES_REQUEST_SUCCESS'
export const ADVERTISEMENT_CATEGORIES_FAIL =
  'ADVERTISEMENT_CATEGORIES_REQUEST_FAIL'

export const LOAD_ADVERTISEMENT = 'LOAD_ADVERTISEMENT'
export const INACTIVE_ADVERTISEMENT = 'INACTIVE_ADVERTISEMENT_REQUEST'
export const ADVERTISEMENT = 'ADVERTISEMENT_REQUEST'
export const ADVERTISEMENT_SUCCESS = 'ADVERTISEMENT_REQUEST_SUCCESS'
export const ADVERTISEMENT_FAIL = 'ADVERTISEMENT_REQUEST_FAIL'
export const ADVERTISEMENTS = 'ADVERTISEMENTS_REQUEST'
export const ADVERTISEMENTS_SUCCESS = 'ADVERTISEMENTS_REQUEST_SUCCESS'
export const ADVERTISEMENTS_FAIL = 'ADVERTISEMENTS_REQUEST_FAIL'

export const LOAD_MANAGER = 'LOAD_MANAGER'
export const INACTIVE_MANAGER_REQUEST = 'INACTIVE_MANAGER_REQUEST'
export const MANAGER = 'MANAGER_REQUEST'
export const MANAGER_SUCCESS = 'MANAGER_REQUEST_SUCCESS'
export const MANAGER_FAIL = 'MANAGER_REQUEST_FAIL'
export const MANAGERS = 'MANAGERS_REQUEST'
export const MANAGERS_SUCCESS = 'MANAGERS_REQUEST_SUCCESS'
export const MANAGERS_FAIL = 'MANAGERS_REQUEST_FAIL'

export const LOAD_PAGE_HEADER = 'LOAD_PAGE_HEADER'
export const PAGES_HEADER_REQUEST = 'PAGES_HEADER_REQUEST'
export const PAGES_HEADER_REQUEST_SUCCESS = 'PAGES_HEADER_REQUEST_SUCCESS'
export const PAGES_HEADER_REQUEST_FAIL = 'PAGES_HEADER_REQUEST_FAIL'
export const PAGE_HEADER_REQUEST = 'PAGE_HEADER_REQUEST'
export const PAGE_HEADER_REQUEST_SUCCESS = 'PAGE_HEADER_REQUEST_SUCCESS'
export const PAGE_HEADER_REQUEST_FAIL = 'PAGE_HEADER_REQUEST_FAIL'

export const LOAD_INSTITUTION = 'LOAD_INSTITUTION'
export const INACTIVE_INSTITUTION_REQUEST = 'INACTIVE_INSTITUTION_REQUEST'
export const INSTITUTION = 'INSTITUTION_REQUEST'
export const INSTITUTION_SUCCESS = 'INSTITUTION_REQUEST_SUCCESS'
export const INSTITUTION_FAIL = 'INSTITUTION_REQUEST_FAIL'
export const INSTITUTIONS = 'INSTITUTIONS_REQUEST'
export const INSTITUTIONS_SUCCESS = 'INSTITUTIONS_REQUEST_SUCCESS'
export const INSTITUTIONS_FAIL = 'INSTITUTIONS_REQUEST_FAIL'

export const LOAD_INSTITUTION_USER = 'LOAD_INSTITUTION_USER'
export const INACTIVE_INSTITUTION_USER_REQUEST =
  'INACTIVE_INSTITUTION_USER_REQUEST'
export const INSTITUTION_USER = 'INSTITUTION_USER_REQUEST'
export const INSTITUTION_USER_SUCCESS = 'INSTITUTION_USER_SUCCESS'
export const INSTITUTION_USER_FAIL = 'INSTITUTION_USER_FAIL'
export const INSTITUTION_USERS = 'INSTITUTION_USERS_REQUEST'
export const INSTITUTION_USERS_SUCCESS =
  'INSTITUTION_USERS_REQUEST_SUCCESS'
export const INSTITUTION_USERS_FAIL = 'INSTITUTION_USERS_REQUEST_FAIL'

export const USER_INSTITUTION = 'USER_INSTITUTION_REQUEST'
export const USER_INSTITUTION_SUCCESS = 'USER_INSTITUTION_SUCCESS'
export const USER_INSTITUTION_FAIL = 'USER_INSTITUTION_FAIL'
export const USER_INSTITUTIONS = 'USER_INSTITUTIONS_REQUEST'
export const USER_INSTITUTIONS_SUCCESS =
  'USER_INSTITUTIONS_REQUEST_SUCCESS'
export const USER_INSTITUTIONS_FAIL = 'USER_INSTITUTIONS_REQUEST_FAIL'

export const LOAD_EVENT = 'LOAD_EVENT'
export const INACTIVE_EVENT = 'INACTIVE_EVENT_REQUEST'
export const EVENT = 'EVENT_REQUEST'
export const FIND_EVENT = 'FIND_EVENT_REQUEST'
export const FIND_EVENT_SUCCESS = 'FIND_EVENT_REQUEST_SUCCESS'
export const FIND_EVENT_FAIL = 'FIND_EVENT_REQUEST_FAIL'
export const EVENT_SUCCESS = 'EVENT_REQUEST_SUCCESS'
export const EVENT_FAIL = 'EVENT_REQUEST_FAIL'
export const EVENTS = 'EVENTS_REQUEST'
export const EVENTS_SUCCESS = 'EVENTS_REQUEST_SUCCESS'
export const EVENTS_FAIL = 'EVENTS_REQUEST_FAIL'
export const IMPORT_REGISTRATIONS = 'IMPORT_REGISTRATIONS'

export const LOAD_PAGE_MEMBER = 'LOAD_PAGE_MEMBER'
export const INACTIVE_PAGE_MEMBER_REQUEST = 'INACTIVE_PAGE_MEMBER_REQUEST'
export const PAGE_MEMBER = 'PAGE_MEMBER_REQUEST'
export const PAGE_MEMBER_SUCCESS = 'PAGE_MEMBER_REQUEST_SUCCESS'
export const PAGE_MEMBER_FAIL = 'PAGE_MEMBER_REQUEST_FAIL'
export const PAGE_MEMBERS = 'PAGE_MEMBERS_REQUEST'
export const PAGE_MEMBERS_SUCCESS = 'PAGE_MEMBERS_REQUEST_SUCCESS'
export const PAGE_MEMBERS_FAIL = 'PAGE_MEMBERS_REQUEST_FAIL'

export const LOAD_ABOUT_PAGE = 'LOAD_ABOUT_PAGE'
export const ABOUT_PAGE = 'ABOUT_PAGE_REQUEST'
export const ABOUT_PAGE_SUCCESS = 'ABOUT_PAGE_REQUEST_SUCCESS'
export const ABOUT_PAGE_FAIL = 'ABOUT_PAGE_REQUEST_FAIL'
export const ABOUT_PAGES = 'ABOUT_PAGES_REQUEST'
export const ABOUT_PAGES_SUCCESS = 'ABOUT_PAGES_REQUEST_SUCCESS'
export const ABOUT_PAGES_FAIL = 'ABOUT_PAGES_REQUEST_FAIL'

export const LOAD_RADIOGRAPHER_PAGE = 'LOAD_RADIOGRAPHER_PAGE'
export const RADIOGRAPHER_PAGES_REQUEST = 'RADIOGRAPHER_PAGES_REQUEST'
export const RADIOGRAPHER_PAGES_REQUEST_SUCCESS =
  'RADIOGRAPHER_PAGES_REQUEST_SUCCESS'
export const RADIOGRAPHER_PAGES_REQUEST_FAIL =
  'RADIOGRAPHER_PAGES_REQUEST_FAIL'
export const RADIOGRAPHER_PAGE_REQUEST = 'RADIOGRAPHER_PAGE_REQUEST'
export const RADIOGRAPHER_PAGE_REQUEST_SUCCESS =
  'RADIOGRAPHER_PAGE_REQUEST_SUCCESS'
export const RADIOGRAPHER_PAGE_REQUEST_FAIL =
  'RADIOGRAPHER_PAGE_REQUEST_FAIL'

export const LOAD_SETTINGS = 'LOAD_SETTINGS'

export const SETTINGS_LIST_REQUEST = 'SETTINGS_LIST_REQUEST'
export const SETTINGS_LIST_REQUEST_SUCCESS =
  'SETTINGS_LIST_REQUEST_SUCCESS'
export const SETTINGS_LIST_REQUEST_FAIL = 'SETTINGS_LIST_REQUEST_FAIL'

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_REQUEST_SUCCESS =
  'UPDATE_SETTINGS_REQUEST_SUCCESS'
export const UPDATE_SETTINGS_REQUEST_FAIL = 'UPDATE_SETTINGS_REQUEST_FAIL'

export const LOAD_ADMINISTRATOR = 'LOAD_ADMINISTRATOR'
export const ADMINISTRATOR = 'ADMINISTRATOR_REQUEST'
export const ADMINISTRATOR_SUCCESS = 'ADMINISTRATOR_REQUEST_SUCCESS'
export const ADMINISTRATOR_FAIL = 'ADMINISTRATOR_REQUEST_FAIL'
export const ADMINISTRATORS = 'ADMINISTRATORS_REQUEST'
export const ADMINISTRATORS_SUCCESS = 'ADMINISTRATORS_REQUEST_SUCCESS'
export const ADMINISTRATORS_FAIL = 'ADMINISTRATORS_REQUEST_FAIL'
export const ADMINISTRATOR_DELETE = 'ADMINISTRATOR_DELETE'

export const CREATE_SUBCATEGORY_DOCUMENT_REQUEST =
  'CREATE_SUBCATEGORY_DOCUMENT_REQUEST'

export const CHANGE_PUBLICATION_CATEGORY = 'CHANGE_PUBLICATION_CATEGORY'
export const GENERIC_REQUEST = 'GENERIC_REQUEST'

export const WEBINAR = 'WEBINAR'
export const WEBINAR_SUCCESS = 'WEBINAR_SUCCESS'
export const WEBINAR_FAIL = 'WEBINAR_FAIL'
export const LOAD_WEBINAR = 'LOAD_WEBINAR'
export const WEBINARS_LIST = 'WEBINARS_LIST'
export const INACTIVE_WEBINAR = 'INACTIVE_WEBINAR'
export const WEBINARS_LIST_SUCCESS = 'WEBINARS_LIST_SUCCESS'
export const WEBINARS_LIST_FAIL = 'WEBINARS_LIST_FAIL'
