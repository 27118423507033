import {WEBINAR_SUCCESS, WEBINAR_FAIL, LOAD_WEBINAR} from '../action.types'
import initialState from '../initial.state'

export default function (state = initialState.webinar, action) {
  switch (action.type) {
    case WEBINAR_SUCCESS:
      return {...action.payload.data}
    case WEBINAR_FAIL:
      return state
    case LOAD_WEBINAR:
      return {...action.webinar}
    default:
      return state
  }
}
