import {
  ABOUT_PAGE_SUCCESS,
  ABOUT_PAGE_FAIL,
  LOAD_ABOUT_PAGE
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.aboutPage, action) {
  switch (action.type) {
    case ABOUT_PAGE_SUCCESS:
      return { ...action.payload.data };
    case ABOUT_PAGE_FAIL:
      return state;
    case LOAD_ABOUT_PAGE:
      return { ...action.aboutPage };
    default:
      return state;
  }
}
