import initialState from '../initial.state';
import {
  PAGE_HEADER_REQUEST_FAIL,
  PAGE_HEADER_REQUEST_SUCCESS,
  LOAD_PAGE_HEADER
} from '../action.types';

export default (state = initialState.pageHeader, action) => {
  switch (action.type) {
    case PAGE_HEADER_REQUEST_SUCCESS:
      return { ...action.payload };
    case PAGE_HEADER_REQUEST_FAIL:
      return state;
    case LOAD_PAGE_HEADER:
      return { ...action.pageHeader };
    default:
      return state;
  }
};
