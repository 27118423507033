import initialState from '../initial.state';
import {
  SETTINGS_LIST_REQUEST_SUCCESS,
  SETTINGS_LIST_REQUEST_FAIL
} from '../action.types';

export default (state = initialState.settingsList, action) => {
  switch (action.type) {
    case SETTINGS_LIST_REQUEST_SUCCESS:
      return { ...action.payload.data };
    case SETTINGS_LIST_REQUEST_FAIL:
      return state;
    default:
      return state;
  }
};
