import { START_LOADER, STOP_LOADER } from "../action.types";

export const start = () => ({
  type: START_LOADER,
  loading: true
});

export const stop = () => ({
  type: STOP_LOADER,
  loading: false
});
