import initialState from '../initial.state'
import {MANAGER_SUCCESS, MANAGER_FAIL, LOAD_MANAGER} from '../action.types'
import managerCategoriesList from '../../shared/managerCategoriesList'

export default (state = initialState.manager, action) => {
  switch (action.type) {
    case MANAGER_SUCCESS:
      const manager = action.payload.data

      const country =
        manager && manager.country
          ? {
              label: manager.country.name,
              value: manager.country.id
            }
          : {}

      const categoryFound = managerCategoriesList.find((option) => {
        return (
          manager && manager.category && option.id === manager.category
        )
      })

      const category = categoryFound
        ? {
            label: categoryFound.name,
            value: categoryFound.id
          }
        : {}

      const payload = {
        ...action.payload.data,
        country,
        category
      }
      return payload
    case MANAGER_FAIL:
      return state
    case LOAD_MANAGER:
      return {...action.manager}
    default:
      return state
  }
}
