import {WEBINARS_LIST_SUCCESS, WEBINARS_LIST_FAIL} from '../action.types'
import initialState from '../initial.state'

export default function (state = initialState.webinars, action) {
  switch (action.type) {
    case WEBINARS_LIST_SUCCESS:
      return {...action.payload.data}
    case WEBINARS_LIST_FAIL:
      return state
    default:
      return state
  }
}
