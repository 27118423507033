import moment from 'moment'

export default {
  loading: true,
  login: {},
  publicationCategories: {
    items: [],
    total: 0
  },
  publicationCategory: {
    isPrivate: false,
    order: 1,
    subcategory: []
  },
  publicationDocument: {order: 1},
  newsList: {
    items: [],
    total: 0
  },
  radiographerpages: {
    items: [],
    total: 0
  },
  radiographerpage: {},
  news: {
    date: moment()
  },
  banners: {
    items: [],
    total: 0
  },
  banner: {},
  certificates: {
    items: [],
    total: 0
  },
  certificate: {},
  advertisementCategories: {
    items: [],
    total: 0
  },
  advertisementCategory: {},
  advertisements: {
    items: [],
    total: 0
  },
  advertisement: {},
  linkCategories: {
    items: [],
    total: 0
  },
  linkCategory: {},
  links: {
    items: [],
    total: 0
  },
  link: {},
  managers: {
    items: [],
    total: 0
  },
  manager: {},
  pageHeader: {},
  pagesHeaders: {
    items: [],
    total: 0
  },
  institutions: {
    items: [],
    total: 0
  },
  institution: {},

  userInstitution: {
    items: [],
    total: 0
  },
  institutionUsers: {
    items: [],
    total: 0
  },
  institutionUser: {},
  events: {
    items: [],
    total: 0
  },
  event: {
    startDate: moment().add(1, 'days'),
    endDate: moment().add(2, 'days'),
    eventType: {}
  },
  pageMembers: {
    items: [],
    total: 0
  },
  pageMember: {},
  aboutPages: {
    items: [],
    total: 0
  },
  aboutPage: {},
  settingsList: {
    items: [],
    total: 0
  },
  settings: {},
  administrators: {
    items: [],
    total: 0
  },
  administrator: {},
  resetpassword: {},
  webinars: {
    items: [],
    total: 0
  },
  webinar: {
    date: moment()
  }
}
