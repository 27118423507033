import InitialState from '../initial.state';

import {
  PAGES_HEADER_REQUEST_SUCCESS,
  PAGES_HEADER_REQUEST_FAIL
} from '../action.types';

export default (state = InitialState.pagesHeaders, action) => {
  switch (action.type) {
    case PAGES_HEADER_REQUEST_SUCCESS:
      return { ...action.payload.data };
    case PAGES_HEADER_REQUEST_FAIL:
      return state;
    default:
      return state;
  }
};
