import { LINKS_SUCCESS, LINKS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.links, action) {
  switch (action.type) {
    case LINKS_SUCCESS:
      return { ...action.payload.data };
    case LINKS_FAIL:
      return state;
    default:
      return state;
  }
}
