import { START_LOADER, STOP_LOADER } from "../action.types";

import initialState from "../initial.state";

export default function(state = initialState.loading, action) {
  switch (action.type) {
    case START_LOADER:
      return action.loading;
    case STOP_LOADER:
      return action.loading;
    default:
      return state;
  }
}
