import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncBannersPage = AsyncComponent(() =>
  import('../../../pages/banners/index')
);

export default [
  {
    path: '/banners',
    component: AsyncBannersPage,
    isAuthenticated: true
  }
];
