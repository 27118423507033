import {
  CERTIFICATE_SUCCESS,
  CERTIFICATE_FAIL,
  LOAD_CERTIFICATE
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.certificate, action) {
  switch (action.type) {
    case CERTIFICATE_SUCCESS:
      return { ...action.payload.data };
    case CERTIFICATE_FAIL:
      return state;
    case LOAD_CERTIFICATE:
      return { ...action.certificate };
    default:
      return state;
  }
}
