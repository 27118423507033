export default {
  USER_OR_PASSWORD_WRONG: 'Invalid user or password',
  USER_DONT_HAVE_PERMISSIONS:
    'This user dont have permissions to access the backoffice',
  USER_NOT_FOUND: 'User not found',
  PASSWORD_NOT_MATCHS: 'Password not matches',
  ATTACHMENTE_NOT_FOUND: 'Attachment not found',
  DESCRIPTION_REQUIRED: 'Description is required',
  DESCRIPTION_MAX_LENGTH:
    'Description can not be longer than {maxLength} characters',
  PASSWORD_REQUIRED: 'Password is required',
  EMAIL_REQUIRED: 'E-mail is required',
  EMAIL_INVALID_EMAIL: 'Invalid e-mail'
};
