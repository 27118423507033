import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncManagement = AsyncComponent(() =>
  import('../../../pages/management/index')
);

const AsyncManagerForm = AsyncComponent(() => {
  return import('../../../pages/management/ManagerForm');
});

export default [
  {
    path: '/management',
    component: AsyncManagement,
    isAuthenticated: true
  },
  {
    path: '/management/new',
    component: AsyncManagerForm,
    isAuthenticated: true
  },
  {
    path: '/management/:managerId',
    component: AsyncManagerForm,
    isAuthenticated: true
  }
];
