import AsyncComponent from '../../components/asynccomponent/AsyncComponent'

const AsyncAdvertisements = AsyncComponent(() =>
  import('../../../pages/advertisements/index')
)

const AsyncAdvertisementForm = AsyncComponent(() =>
  import('../../../pages/advertisements/AdvertisementForm')
)

export default [
  {
    path: '/advertisements',
    component: AsyncAdvertisements,
    isAuthenticated: true
  },
  {
    path: '/advertisements/new',
    component: AsyncAdvertisementForm,
    isAuthenticated: true
  },
  {
    path: '/advertisements/:advertisementId/edit',
    component: AsyncAdvertisementForm,
    isAuthenticated: true
  }
]
