import { CERTIFICATES_SUCCESS, CERTIFICATES_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.certificates, action) {
  switch (action.type) {
    case CERTIFICATES_SUCCESS:
      return { ...action.payload.data };
    case CERTIFICATES_FAIL:
      return state;
    default:
      return state;
  }
}
