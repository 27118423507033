import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncNewsPage = AsyncComponent(() =>
  import('../../../pages/newsList/index')
);

const AsyncNewsForm = AsyncComponent(() =>
  import('../../../pages/newsList/NewsForm')
);

export default [
  {
    path: '/news',
    component: AsyncNewsPage,
    isAuthenticated: true
  },
  {
    path: '/news/new',
    component: AsyncNewsForm,
    isAuthenticated: true
  },
  {
    path: '/news/:newsId/edit',
    component: AsyncNewsForm,
    isAuthenticated: true
  }
];
