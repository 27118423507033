import { INSTITUTIONS_SUCCESS, INSTITUTIONS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.institutions, action) {
  switch (action.type) {
    case INSTITUTIONS_SUCCESS:
      return { ...action.payload.data };
    case INSTITUTIONS_FAIL:
      return state;
    default:
      return state;
  }
}
