import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './shared/components/routes/AuthenticatedRoute';
import UnauthenticatedRoute from './shared/components/routes/UnauthenticatedRoute';
import NotFound from './pages/notfound/NotFound';
import Routes from './shared/infra/routes/rootRoutes';

export default ({ childProps }) => (
  <Switch>
    {Routes.map(
      (route, key) =>
        route.isAuthenticated ? (
          <AuthenticatedRoute
            key={key}
            path={route.path}
            exact
            component={route.component}
            props={childProps}
          />
        ) : (
          <UnauthenticatedRoute
            path={route.path}
            key={key}
            exact
            component={route.component}
            props={childProps}
          />
        )
    )}
    <Route component={NotFound} />
  </Switch>
);
