import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const DefaultAppBar = ({ isOpen, classes, handleDrawer, logout }) => (
  <AppBar
    position="static"
    className={classNames(classes.appBar, isOpen && classes.appBarShift)}
  >
    <Toolbar disableGutters={!isOpen}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawer}
        className={classNames(classes.menuButton)}
      >
        <Icon className={classes.menuIcon}>menu</Icon>
      </IconButton>
      <Typography
        variant="h6"
        color="inherit"
        className={classNames(classes.titleAppBar)}
        noWrap
      >
        EFRS
      </Typography>
      <IconButton
        onClick={logout}
        className={classes.logOutButton}
        color="inherit"
      >
        <Icon>exit_to_app</Icon>
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default DefaultAppBar;
