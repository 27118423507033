import React from 'react'

import {createMuiTheme} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/styles'
import {CssBaseline} from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      50: '#e7f0fe',
      100: '#c3d8fc',
      200: '#9cbffa',
      300: '#74a5f8',
      400: '#5691f7',
      500: '#387ef5',
      600: '#3276f4',
      700: '#2b6bf2',
      800: '#2461f0',
      900: '#174eee',
      A100: '#ffffff',
      A200: '#ebf0ff',
      A400: '#b8c8ff',
      A700: '#9fb4ff',
      contrastDefaultColor: 'dark'
    },
    secondary: {
      50: '#ecf3f4',
      100: '#d0e1e4',
      200: '#b1ced2',
      300: '#91bac0',
      400: '#7aabb3',
      500: '#629ca5',
      600: '#5a949d',
      700: '#508a93',
      800: '#46808a',
      900: '#346e79',
      A100: '#c6f6ff',
      A200: '#93eeff',
      A400: '#60e6ff',
      A700: '#47e2ff',
      contrastDefaultColor: 'dark'
    },
    error: {
      50: '#fee8e8',
      100: '#fcc5c5',
      200: '#fa9e9e',
      300: '#f87777',
      400: '#f75a5a',
      500: '#f53d3d',
      600: '#f43737',
      700: '#f22f2f',
      800: '#f02727',
      900: '#ee1a1a',
      A100: '#ffffff',
      A200: '#ffeded',
      A400: '#ffbaba',
      A700: '#ffa1a1',
      contrastDefaultColor: 'dark'
    }
  }
})

function Theme(Component) {
  function WithRoot(props) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    )
  }

  return WithRoot
}

export default Theme
