import { NEWS_LIST_SUCCESS, NEWS_LIST_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.newsList, action) {
  switch (action.type) {
    case NEWS_LIST_SUCCESS:
      return { ...action.payload.data };
    case NEWS_LIST_FAIL:
      return state;
    default:
      return state;
  }
}
