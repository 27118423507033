import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import Loading from "../../../assets/images/loading.svg";

import "./Loader.css";

class Loader extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="loader-container">
          <CssBaseline />
          <ReactSVG src={Loading} />
        </div>
      );
    }
    return this.props.children;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.loading,
  };
}

export default connect(mapStateToProps)(Loader);
