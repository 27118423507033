import {
  RADIOGRAPHER_PAGES_REQUEST_SUCCESS,
  RADIOGRAPHER_PAGES_REQUEST_FAIL
} from '../action.types';

import initialState from '../initial.state';

export default function(state = initialState.radiographerpages, action) {
  switch (action.type) {
    case RADIOGRAPHER_PAGES_REQUEST_SUCCESS:
      return { ...action.payload.data };
    case RADIOGRAPHER_PAGES_REQUEST_FAIL:
      return state;
    default:
      return state;
  }
}
