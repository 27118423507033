import {
  LOAD_EVENT,
  FIND_EVENT_SUCCESS,
  FIND_EVENT_FAIL,
  EVENT_SUCCESS,
  EVENT_FAIL
} from '../action.types';
import initialState from '../initial.state';
import eventTypeLists from '../../shared/eventTypesList';

export default function(state = initialState.event, action) {
  switch (action.type) {
    case EVENT_SUCCESS:
      return { ...action.payload.data };
    case EVENT_FAIL:
      return state;
    case FIND_EVENT_SUCCESS:
      const event = action.payload.data;
      const eventType =
        eventTypeLists.find(({ id }) => id === event.eventType) || {};
      return {
        ...event,
        eventType: {
          value: eventType.id,
          name: eventType.name
        }
      };
    case FIND_EVENT_FAIL:
      return state;
    case LOAD_EVENT:
      return { ...action.event };
    default:
      return state;
  }
}
