import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncUserInstitution = AsyncComponent(() =>
  import('../../../pages/userInstitution/index')
);

export default [
  {
    path: '/user-institution',
    component: AsyncUserInstitution,
    isAuthenticated: true
  }
];
