import {
  PUBLICATION_CATEGORIES_SUCCESS,
  PUBLICATION_CATEGORIES_FAIL,
  CHANGE_PUBLICATION_CATEGORY
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.publicationCategories, action) {
  switch (action.type) {
    case PUBLICATION_CATEGORIES_SUCCESS:
      return { ...action.payload.data };
    case PUBLICATION_CATEGORIES_FAIL:
      return state;
    case CHANGE_PUBLICATION_CATEGORY:
      return action.data;
    default:
      return state;
  }
}
