import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncPublicationCategories = AsyncComponent(() =>
  import('../../../pages/publicationCategories/index')
);

export default [
  {
    path: '/publication-categories',
    component: AsyncPublicationCategories,
    isAuthenticated: true
  }
];
