import {
  LOAD_INSTITUTION_USER,
  INSTITUTION_USER_SUCCESS,
  INSTITUTION_USER_FAIL
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.userInstitution, action) {
  switch (action.type) {
    case INSTITUTION_USER_SUCCESS:
      return { ...action.payload.data };
    case INSTITUTION_USER_FAIL:
      return state;
    case LOAD_INSTITUTION_USER:
      return { ...action.institutionUser };
    default:
      return state;
  }
}
