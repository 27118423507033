import AsyncComponent from '../../components/asynccomponent/AsyncComponent'

const AsyncWebinarsPage = AsyncComponent(() =>
  import('../../../pages/webinars/index')
)

const AsyncWebinarForm = AsyncComponent(() =>
  import('../../../pages/webinars/WebinarForm')
)

export default [
  {
    path: '/webinars',
    component: AsyncWebinarsPage,
    isAuthenticated: true
  },
  {
    path: '/webinars/new',
    component: AsyncWebinarForm,
    isAuthenticated: true
  },
  {
    path: '/webinars/:webinarId/edit',
    component: AsyncWebinarForm,
    isAuthenticated: true
  }
]
