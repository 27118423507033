import {
  INSTITUTION_USERS_SUCCESS,
  INSTITUTION_USERS_FAIL
} from '../action.types';
import initialState from '../initial.state';
import institutionUserRoleList from '../../shared/institutionUserRoleList';

export default function(state = initialState.institutionUsers, action) {
  switch (action.type) {
    case INSTITUTION_USERS_SUCCESS:
      return {
        ...action.payload.data,
        items: action.payload.data.items.map(user => {
          const userType = institutionUserRoleList.find(type =>
            user.roles.find(role => type.id === role.id)
          );

          return {
            userId: user.id,
            name: user.profile.name,
            email: user.email,
            roleId: {
              label: userType.name,
              value: userType.id
            }
          };
        })
      };
    case INSTITUTION_USERS_FAIL:
      return state;
    default:
      return state;
  }
}
