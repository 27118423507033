import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const asyncPageHeader = AsyncComponent(() =>
  import('../../../pages/pageheader/pageheader.index')
);

export default [
  {
    path: '/page-header',
    component: asyncPageHeader,
    isAuthenticated: true
  }
];
