import { ADVERTISEMENTS_SUCCESS, ADVERTISEMENTS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.advertisements, action) {
  switch (action.type) {
    case ADVERTISEMENTS_SUCCESS:
      return { ...action.payload.data };
    case ADVERTISEMENTS_FAIL:
      return state;
    default:
      return state;
  }
}
