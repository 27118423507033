import {
  ADMINISTRATOR_SUCCESS,
  ADMINISTRATOR_FAIL,
  LOAD_ADMINISTRATOR
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.administrator, action) {
  switch (action.type) {
    case ADMINISTRATOR_SUCCESS:
      return { ...action.payload.data };
    case ADMINISTRATOR_FAIL:
      return state;
    case LOAD_ADMINISTRATOR:
      return { ...action.administrator };
    default:
      return state;
  }
}
