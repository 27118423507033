import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncInstitutions = AsyncComponent(() =>
  import('../../../pages/institutions/index')
);

export default [
  {
    path: '/institutions',
    component: AsyncInstitutions,
    isAuthenticated: true
  }
];
