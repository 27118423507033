import { PAGE_MEMBERS_SUCCESS, PAGE_MEMBERS_FAIL } from '../action.types';
import initialState from '../initial.state';
import pageMemberTypeList from '../../shared/pageMemberTypeList';

export default function(state = initialState.pageMembers, action) {
  switch (action.type) {
    case PAGE_MEMBERS_SUCCESS:
      return {
        ...action.payload.data,
        items: action.payload.data.items.map(pageMember => {
          return {
            ...pageMember,
            type: {
              label: pageMemberTypeList.find(
                option => option.id === pageMember.type
              ).name,
              value: pageMember.type
            },
            country: {
              label: pageMember.country.name,
              value: pageMember.country.id
            }
          };
        })
      };
    case PAGE_MEMBERS_FAIL:
      return state;
    default:
      return state;
  }
}
