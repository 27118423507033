import {
  CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL,
  REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL,
  LOAD_PUBLICATION_DOCUMENT
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.publicationDocument, action) {
  switch (action.type) {
    case LOAD_PUBLICATION_DOCUMENT:
      return { ...action.document };
    case CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL:
      return state;
    case REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL:
      return state;
    default:
      return state;
  }
}
