import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncLinkCategories = AsyncComponent(() =>
  import('../../../pages/linkCategories/index')
);

export default [
  {
    path: '/link-categories',
    component: AsyncLinkCategories,
    isAuthenticated: true
  }
];
