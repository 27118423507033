import React, { Component } from 'react';

import Loader from './shared/components/loader/Loader';
import LoadingBar from 'react-redux-loading-bar';
import Routes from './Routes';

class App extends Component {
  render() {
    return (
      <Loader>
        <LoadingBar
          style={{
            zIndex: 10000,
            backgroundColor: 'rgba(117, 117, 117)',
            height: '5px'
          }}
        />
        <Routes />
      </Loader>
    );
  }
}

export default App;
