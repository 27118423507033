export default [
  {
    id: 1,
    name: 'Annual Meeting',
    isPrivate: true,
    isPayed: true
  },
  {
    id: 2,
    name: 'Mid-Term Meeting',
    isPrivate: true,
    isPayed: false
  },
  {
    id: 3,
    name: 'External Event',
    isPrivate: false,
    isPayed: false
  }
];
