import { ADMINISTRATORS_SUCCESS, ADMINISTRATORS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.administrators, action) {
  switch (action.type) {
    case ADMINISTRATORS_SUCCESS:
      return { ...action.payload.data };
    case ADMINISTRATORS_FAIL:
      return state;
    default:
      return state;
  }
}
