import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncEventsPage = AsyncComponent(() =>
  import('../../../pages/events/index')
);

const AsyncEventForm = AsyncComponent(() =>
  import('../../../pages/events/EventForm')
);

const AsyncReserves = AsyncComponent(() =>
  import('../../../pages/events/reserves/EventReserves')
);

export default [
  {
    path: '/events',
    component: AsyncEventsPage,
    isAuthenticated: true
  },
  {
    path: '/events/new',
    component: AsyncEventForm,
    isAuthenticated: true
  },
  {
    path: '/events/:eventId/reserves',
    component: AsyncReserves,
    isAuthenticated: true
  },
  {
    path: '/events/:eventId/edit',
    component: AsyncEventForm,
    isAuthenticated: true
  }
];
