import {MANAGERS_SUCCESS, MANAGERS_FAIL} from '../action.types'
import initialState from '../initial.state'
import managerCategoriesList from '../../shared/managerCategoriesList'

export default function (state = initialState.managers, action) {
  switch (action.type) {
    case MANAGERS_SUCCESS:
      return {
        ...action.payload.data,
        items: action.payload.data.items.map((manager) => {
          const cat = managerCategoriesList.find((option) => {
            return option.id === manager.category
          })

          return {
            ...manager,
            country: {
              label: manager.country.name,
              value: manager.country.id
            },
            category: cat
              ? {
                  label: cat.name,
                  value: cat.id
                }
              : {}
          }
        })
      }
    case MANAGERS_FAIL:
      return state
    default:
      return state
  }
}
