import { EVENTS_SUCCESS, EVENTS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.events, action) {
  switch (action.type) {
    case EVENTS_SUCCESS:
      return { ...action.payload.data };
    case EVENTS_FAIL:
      return state;
    default:
      return state;
  }
}
