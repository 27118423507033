import initialState from '../initial.state';
import { LOAD_SETTINGS } from '../action.types';

export default (state = initialState.settings, action) => {
  switch (action.type) {
    case LOAD_SETTINGS:
      return { ...action.settings };
    default:
      return state;
  }
};
