import { loadingBarMiddleware } from "react-redux-loading-bar";
import thunk from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
import AxiosClient from "../shared/infra/http/AxiosConfig";

import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewareConfig = {
  returnRejectedPromiseOnError: true,
};

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        axiosMiddleware(AxiosClient, middlewareConfig),
        loadingBarMiddleware({
          promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAIL"],
        })
      )
    )
  );

  return store;
}
