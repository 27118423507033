import React, {Component} from 'react'
import {
  List,
  Icon,
  Drawer,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import LiveTvIcon from '@material-ui/icons/LiveTv'

import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AppBar from '../appbar/AppBar'
import withRoot from '../root/withRoot'
import Logo from '../../../assets/images/efrs_logo.svg'

const drawerWidth = 290

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuIcon: {
    color: 'white'
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    height: '100%',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
    width: '100%',
    padding: theme.spacing(3),
    height: 'calc(100% - 56px)',
    paddingBottom: '100px',
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  headerLogo: {
    display: 'inline-block',
    height: '55px'
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  drawerInner: {
    width: drawerWidth
  },
  logo: {
    width: '160px'
  },
  titleAppBar: {
    flex: 1
  },
  menuItem: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
  }
})

const style = {
  ripple: {style: {color: 'rgba(0,0,0,0.75)'}},
  listContainer: {
    paddingTop: 0,
    paddingBottom: 0
  }
}

class ResponsiveDrawer extends Component {
  state = {
    mobileOpen: false,
    open: true
  }

  renderMenuList() {
    const {classes} = this.props
    return (
      <List className={classes.list} style={style.listContainer}>
        <NavLink
          className={classes.menuItem}
          to='/banners'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>image</Icon>
            </ListItemIcon>
            <ListItemText primary='Banners' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/page-header'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>pageview</Icon>
            </ListItemIcon>
            <ListItemText primary='Headers' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/institutions'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>school</Icon>
            </ListItemIcon>
            <ListItemText primary='Institutions' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/user-institution'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>assignment_ind</Icon>
            </ListItemIcon>
            <ListItemText primary='Users' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/news'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>mail</Icon>
            </ListItemIcon>
            <ListItemText primary='News' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/events'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem>
            <ListItemIcon>
              <Icon>event</Icon>
            </ListItemIcon>
            <ListItemText primary='Events' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/webinars'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem>
            <ListItemIcon>
              <LiveTvIcon />
            </ListItemIcon>
            <ListItemText primary='Webinars' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/radiographerpages'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>pages</Icon>
            </ListItemIcon>
            <ListItemText primary='Radiographer Pages' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/about-pages'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>info</Icon>
            </ListItemIcon>
            <ListItemText primary='About Page' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/management'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>people</Icon>
            </ListItemIcon>
            <ListItemText primary='Management Page' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/page-members'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>supervised_user_circle</Icon>
            </ListItemIcon>
            <ListItemText primary='Members Page' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/publication-categories'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>library_books</Icon>
            </ListItemIcon>
            <ListItemText primary='Publications' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/link-categories'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>link</Icon>
            </ListItemIcon>
            <ListItemText primary='Links' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/advertisements'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>volume_mute</Icon>
            </ListItemIcon>
            <ListItemText primary='Advertisement' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/settings'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>settings</Icon>
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/administrators'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>contact_mail</Icon>
            </ListItemIcon>
            <ListItemText primary='Administrators' />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.menuItem}
          to='/certificates'
          activeClassName='menu-link-active'
          exact
        >
          <ListItem TouchRippleProps={style.ripple} button>
            <ListItemIcon>
              <Icon>business_center</Icon>
            </ListItemIcon>
            <ListItemText primary='Certificates' />
          </ListItem>
        </NavLink>
      </List>
    )
  }

  handleDrawer = () => this.setState({open: !this.state.open})
  handleDrawerClose = () => this.setState({open: false})

  logOut = () => {
    localStorage.clear()
    this.props.history.push('/login')
  }

  render() {
    const {classes} = this.props
    const {open} = this.state
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            isOpen={open}
            classes={classes}
            handleDrawer={this.handleDrawer}
            logout={this.logOut}
          />

          <Drawer
            variant='permanent'
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              )
            }}
            open={open}
          >
            <div className={classes.drawerInner}>
              <div className={classes.drawerHeader}>
                <img
                  className={classes.headerLogo}
                  src={Logo}
                  alt='EFRS'
                />
              </div>
              <Divider />
              {this.renderMenuList()}
            </div>
          </Drawer>
          <main
            role='button'
            onClick={this.handleDrawerClose}
            onKeyDown={this.handleDrawerClose}
            className={classes.content}
          >
            {this.props.children}
          </main>
        </div>
      </div>
    )
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired
}

ResponsiveDrawer = withStyles(styles)(ResponsiveDrawer)

export default withRoot(ResponsiveDrawer)
