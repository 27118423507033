import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncCertificatesPage = AsyncComponent(() =>
  import('../../../pages/certificates/index')
);

export default [
  {
    path: '/certificates',
    component: AsyncCertificatesPage,
    isAuthenticated: true
  }
];
