import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import {loadingBarReducer} from 'react-redux-loading-bar'

import {reducer as formReducer} from 'redux-form'

import loaderReducer from './loader/loader.reducer'
import loginReducer from './login/login.reducer'

import resetPasswordReducer from './resetpassword/resetpassword.reducer'

import publicationCategoriesReducer from './publicationCategories/publicationCateories.reducer'
import publicationCategoryReducer from './publicationCategories/publicationCategory.reducer'

import publicationDocumentReducer from './publicationDocument/publicationDocument.reducer'

import newsReducer from './newsList/news.reducer'
import newsListReducer from './newsList/newsList.reducer'

import bannerReducer from './banners/banner.reducer'
import bannersReducer from './banners/banners.reducer'

import advertisementCategoryReducer from './advertisementCategories/advertisementCategory.reducer'
import advertisementCategoriesReducer from './advertisementCategories/advertisementCategories.reducer'

import advertisementReducer from './advertisements/advertisement.reducer'
import advertisementsReducer from './advertisements/advertisements.reducer'

import linkCategoryReducer from './linkCategories/linkCategory.reducer'
import linkCategoriesReducer from './linkCategories/linkCategories.reducer'

import linkReducer from './links/link.reducer'
import linksReducer from './links/links.reducer'

import managerReducer from './managers/manager.reducer'
import managersReducer from './managers/managers.reducer'

import institutionReducer from './institutions/institution.reducer'
import institutionsReducer from './institutions/institutions.reducer'

import institutionUserReducer from './institutionUsers/institutionUser.reducer'
import institutionUsersReducer from './institutionUsers/institutionUsers.reducer'

import eventReducer from './events/event.reducer'
import eventsReducer from './events/events.reducer'

import pagesHeaderReducer from './pageheader/pagesheader.reducer'
import pageHeaderReducer from './pageheader/pageheader.reducer'

import pageMemberReducer from './pageMembers/pageMember.reducer'
import pageMembersReducer from './pageMembers/pageMembers.reducer'

import aboutPagesReducer from './aboutPages/aboutPages.reducer'
import aboutPageReducer from './aboutPages/aboutPage.reducer'

import radiographerPageReducer from './radiographerpage/radiographerpage.reducer'
import radiographerPagesReducer from './radiographerpage/radiographerpages.reducer'

import settingsListReducer from './settings/settingsList.reducer'
import settigsReducer from './settings/settings.reducer'

import administratorReducer from './administrators/administrator.reducer'
import administratorsReducer from './administrators/administrators.reducer'

import certificateReducer from './certificates/certificate.reducer'
import certificatesReducer from './certificates/certificates.reducer'

import userInstitutionReducer from './userInstitution/userInstitution.reducer'
import userInstitutionsReducer from './userInstitution/userInstituions.reducer'

import webinarsReducer from './webinars/webinars.reducer'
import webinarReducer from './webinars/webinar.reducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    loadingBar: loadingBarReducer,
    loading: loaderReducer,
    login: loginReducer,
    resetpassword: resetPasswordReducer,
    managers: managersReducer,
    manager: managerReducer,
    publicationCategories: publicationCategoriesReducer,
    publicationCategory: publicationCategoryReducer,
    publicationDocument: publicationDocumentReducer,
    newsList: newsListReducer,
    news: newsReducer,
    webinars: webinarsReducer,
    webinar: webinarReducer,
    banners: bannersReducer,
    banner: bannerReducer,
    advertisementCategories: advertisementCategoriesReducer,
    advertisementCategory: advertisementCategoryReducer,
    advertisements: advertisementsReducer,
    advertisement: advertisementReducer,
    linkCategories: linkCategoriesReducer,
    linkCategory: linkCategoryReducer,
    links: linksReducer,
    link: linkReducer,
    institution: institutionReducer,
    institutions: institutionsReducer,
    institutionUser: institutionUserReducer,
    institutionUsers: institutionUsersReducer,
    events: eventsReducer,
    event: eventReducer,
    pagesHeader: pagesHeaderReducer,
    pageHeader: pageHeaderReducer,
    pageMember: pageMemberReducer,
    pageMembers: pageMembersReducer,
    aboutPages: aboutPagesReducer,
    aboutPage: aboutPageReducer,
    radiographerPage: radiographerPageReducer,
    radiographerPages: radiographerPagesReducer,
    settingsList: settingsListReducer,
    settings: settigsReducer,
    administrators: administratorsReducer,
    administrator: administratorReducer,
    userInstitution: userInstitutionReducer,
    userInstitutions: userInstitutionsReducer,
    certificate: certificateReducer,
    certificates: certificatesReducer
  })

export default createRootReducer
