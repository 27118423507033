import "typeface-roboto";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { stop } from "./redux/loader/loader.actions";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import { ToastContainer } from "react-toastify";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./redux/configureStore";

const store = configureStore(/* provide initial state if any */);

setTimeout(() => {
  store.dispatch(stop());
}, 1000);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Fragment>
        <App />
        <ToastContainer />
      </React.Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
