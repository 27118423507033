import { BANNERS_SUCCESS, BANNERS_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.banners, action) {
  switch (action.type) {
    case BANNERS_SUCCESS:
      return { ...action.payload.data };
    case BANNERS_FAIL:
      return state;
    default:
      return state;
  }
}
