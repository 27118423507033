import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncPage = AsyncComponent(() =>
  import('../../../pages/radiographerPage/index')
);

const AsyncForm = AsyncComponent(() =>
  import('../../../pages/radiographerPage/RadiographerPageForm')
);

export default [
  {
    path: '/radiographerpages',
    component: AsyncPage,
    isAuthenticated: true
  },
  {
    path: '/radiographerpages/new',
    component: AsyncForm,
    isAuthenticated: true
  },
  {
    path: '/radiographerpages/:id/edit',
    component: AsyncForm,
    isAuthenticated: true
  }
];
