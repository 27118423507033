import {
  LOAD_PUBLICATION_CATEGORY,
  PUBLICATION_CATEGORY_SUCCESS,
  PUBLICATION_CATEGORY_FAIL,
  CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL,
  REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.publicationCategory, action) {
  switch (action.type) {
    case PUBLICATION_CATEGORY_SUCCESS:
      return { ...action.payload.data };
    case PUBLICATION_CATEGORY_FAIL:
      return state;
    case LOAD_PUBLICATION_CATEGORY:
      return { ...action.publicationCategory };
    case CREATE_PUBLICATION_DOCUMENT_REQUEST_FAIL:
      return state;
    case REMOVE_PUBLICATION_DOCUMENT_REQUEST_FAIL:
      return state;
    default:
      return state;
  }
}
