import {actionTypes} from 'redux-form'
import {
  ADVERTISEMENT_SUCCESS,
  ADVERTISEMENT_FAIL,
  LOAD_ADVERTISEMENT
} from '../action.types'
import initialState from '../initial.state'

export default function (state = initialState.advertisement, action) {
  switch (action.type) {
    case ADVERTISEMENT_SUCCESS:
      const {data} = action.payload
      return {
        ...data,
        categoryId: data.category ? data.category.id : undefined
      }
    case ADVERTISEMENT_FAIL:
      return state
    case LOAD_ADVERTISEMENT:
      const {advertisement} = action
      return {
        ...advertisement,
        categoryId: advertisement.category
          ? advertisement.category.id
          : undefined
      }
    default:
      return state
  }
}
