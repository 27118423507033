import { toast } from 'react-toastify';
import ConstantMessages from '../../ContantMessages';

const toastConfig = {
  autoClose: 8000,
  draggablePercent: 60
};

const getMessage = message => ConstantMessages[message] || message;

const success = message => toast.success(getMessage(message), toastConfig);
const error = message => toast.error(getMessage(message), toastConfig);
const info = message => toast.info(getMessage(message), toastConfig);
const warn = message => toast.warn(getMessage(message), toastConfig);

export default {
  success,
  error,
  info,
  warn
};
