import {
  ADVERTISEMENT_CATEGORIES_SUCCESS,
  ADVERTISEMENT_CATEGORIES_FAIL
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.advertisementCategories, action) {
  switch (action.type) {
    case ADVERTISEMENT_CATEGORIES_SUCCESS:
      return { ...action.payload.data };
    case ADVERTISEMENT_CATEGORIES_FAIL:
      return state;
    default:
      return state;
  }
}
