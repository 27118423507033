import { Redirect, Route } from 'react-router-dom';

import Layout from '../layout/Layout';
import React from 'react';

export default ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !!localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN) ? (
        <Layout {...props} {...cProps}>
          <C {...props} {...cProps} />
        </Layout>
      ) : (
        <Redirect
          to={`/login?redirect=${props.location.pathname}${
            props.location.search
          }`}
        />
      )
    }
  />
);
