import BaseRoutes from './base'
import PageHeaderRoutes from './pageheader.routes'
import PageMembersRoutes from './pageMebers.routes'
import PublicationCategoriesRoutes from './publicationCategories.routes'
import NewsListRoutes from './newsList.routes'
import WebinarsRoutes from './webinars.routes'
import BannersRoutes from './banners.routes'
import AdvertisementCategoriesRoutes from './advertisementCategories.routes'
import LinkCategoriesRoutes from './linkCategories.routes'
import ManagementRoutes from './management.routes'
import InstitutionsRoutes from './institutions.routes'
import EventsRoutes from './events.routes'
import AboutPageRoutes from './aboutPages.routes'
import RadiographerPageRoutes from './radiographerpaage.routes'
import AdministratorsRoutes from './administrators.routes'
import CertificatesRoutes from './certificates.routes'
import UserInstitutionRoutes from './userInstitution.routes'

export default [
  ...BaseRoutes,
  ...PageHeaderRoutes,
  ...PageMembersRoutes,
  ...PublicationCategoriesRoutes,
  ...NewsListRoutes,
  ...BannersRoutes,
  ...AdvertisementCategoriesRoutes,
  ...LinkCategoriesRoutes,
  ...ManagementRoutes,
  ...InstitutionsRoutes,
  ...EventsRoutes,
  ...AboutPageRoutes,
  ...RadiographerPageRoutes,
  ...AdministratorsRoutes,
  ...CertificatesRoutes,
  ...UserInstitutionRoutes,
  ...WebinarsRoutes
]
