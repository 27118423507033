import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncAdministratorsPage = AsyncComponent(() =>
  import('../../../pages/administrators/index')
);

export default [
  {
    path: '/administrators',
    component: AsyncAdministratorsPage,
    isAuthenticated: true
  }
];
