import AsyncComponent from '../../components/asynccomponent/AsyncComponent';

const AsyncAboutPages = AsyncComponent(() =>
  import('../../../pages/aboutPages/index')
);

const AsyncAboutPageForm = AsyncComponent(() => {
  return import('../../../pages/aboutPages/AboutPageForm');
});

export default [
  {
    path: '/about-pages',
    component: AsyncAboutPages,
    isAuthenticated: true
  },
  {
    path: '/about-pages/:aboutPageId',
    component: AsyncAboutPageForm,
    isAuthenticated: true
  }
];
