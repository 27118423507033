import { ABOUT_PAGES_SUCCESS, ABOUT_PAGES_FAIL } from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.aboutPages, action) {
  switch (action.type) {
    case ABOUT_PAGES_SUCCESS:
      return {
        ...action.payload.data
      };
    case ABOUT_PAGES_FAIL:
      return state;
    default:
      return state;
  }
}
