import {
  PAGE_MEMBER_SUCCESS,
  PAGE_MEMBER_FAIL,
  LOAD_PAGE_MEMBER
} from '../action.types';
import initialState from '../initial.state';

export default function(state = initialState.pageMember, action) {
  switch (action.type) {
    case PAGE_MEMBER_SUCCESS:
      return { ...action.payload.data };
    case PAGE_MEMBER_FAIL:
      return state;
    case LOAD_PAGE_MEMBER:
      return { ...action.pageMember };
    default:
      return state;
  }
}
